<!-- 搭建服务 -->
<template>
  <div class="BuildService">
              <h3>
                 会展中心为您提供标准化展位搭建和展具租赁服务
            </h3>
            <div class="content">
                <div v-for="(item,index) in contentss" :key="index">
                    <img :src="item.imgs" alt="">
                    <p class="contenttext">{{item.text}}</p>
                </div>      
            </div>
            <!-- <h2>
                标摊详细介绍
            </h2>
            <div class="detail">
                <img src="../../assets/image/标摊详细介绍.png" alt="">
            </div> -->
  </div>
</template>

<script>
export default {
    data() {
        return {
             nav:[
                {title:"餐饮服务",background: "#fff",color: "#000"},
                {title:"展具租赁",background: "#fff",color: "#000"},
                {title:"广告服务",background: "#fff",color: "#000"},
                {title:"搭建服务",background: "#197cc3",color: "#fff"},
            ],     
            activeNames: ['0'], 
            navtitle:"搭建服务" ,
            contentss:[
            {
            text:"标准展位（标摊）",
            // text:"标准展位（标摊）",
            imgs:require('../../assets/image/标准展位（标摊）.png'),
            },
            // {
            // text:"标摊介绍",
            // // text:"标摊介绍",
            // imgs:require('../../assets/image/标摊介绍.png'),
            // },
            {
            text:"特装展位",
            // text:"特装展位",
            imgs:require('../../assets/image/特装展位.png'),
            }
            
        ]
        }
    },
    created() {
    },
    methods:{
        skip(val){
            if(val == "餐饮服务"){
                this.$router.push("/FoodandBeverages")
            }
        }
    }
}
</script>

<style scoped>
    .exhibition{
        width: 100%;
        height: 100%;
    }
    h3{
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 0.3rem;
        margin-top: 0.6rem;
        text-align: center
    }
    .content{
        padding: 0.2rem;
        background-color: #FAFAFA;
        margin-bottom: 0.3rem;
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
        margin-top:0.5rem;
        text-align: center
        
    }
    .contenttext{
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 0.3rem;
        margin-bottom: 0.1rem;
    }
    .content img{
        width: 3.2rem;
        border: 1px dashed black;
    }
    /* h2{
        font-size: 0.3rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height:0.3rem;
        margin-top: 1rem;
        text-align: center
    }
    .detail{
        width: 99%;
        margin-top: 0.3rem
    }
    .detail img{
        width: 99%;
    } */
    .bot{
        margin-top: 0.5rem
    }
</style>