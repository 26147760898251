<!-- 展会服务 -->
<template>
  <div class="ExhibitionServices">
      <div class="nav">
          <H5Carousel/>
      </div>
      <div class="contents">
         <div class="navs">
              <van-collapse v-model="activeNames">
                <van-collapse-item :title="navtitle">
                    <div class="titleitem" v-for="(item,index) in nav" :key="index" @click="gopage(index)" :style="{background:item.background,color:item.color}">
                         {{show == true?item.title:item.titles}}
                    </div>
                </van-collapse-item>
              </van-collapse>
          </div>
              <div class="content" v-if="navtitle == '餐饮服务'|| navtitle == 'Food and Beverages'">
            <FoodandBeverages/>
            </div>
            <div class="content" v-else-if="navtitle == '展具租赁'|| navtitle == 'Exhibition equipment rental'">
            <ExhibitionEquipmentRental />
            </div>
            <div class="content" v-else-if="navtitle == '广告服务'|| navtitle == 'Advertising services'">
            <AdvertisingServices />
            </div>
            <div class="content" v-else-if="navtitle == '搭建服务'|| navtitle == 'Build service'">
            <BuildService />
            </div>
      </div>
      <div class="bot">
          <H5Bottom/>
      </div>
  </div>
</template>

<script>
import H5Carousel from '../../components/h5-carousel.vue'
import H5Bottom from '../../components/h5bottom.vue'
import FoodandBeverages from "../../components/h5ExhibitionServices/FoodandBeverages.vue";
import ExhibitionEquipmentRental from "../../components/h5ExhibitionServices/ExhibitionEquipmentRental.vue";
import AdvertisingServices from "../../components/h5ExhibitionServices/AdvertisingServices.vue";
import BuildService from "../../components/h5ExhibitionServices/BuildService.vue";
export default {
    components:{
        H5Carousel,H5Bottom,FoodandBeverages,ExhibitionEquipmentRental,AdvertisingServices,BuildService
    },
    data() {
        return {
             nav:[
                {title:"餐饮服务",background: "#fff",color: "#000",titles:"Food and Beverages"},
                {title:"展具租赁",background: "#fff",color: "#000",titles:"Exhibition equipment rental"},
                {title:"广告服务",background: "#fff",color: "#000",titles:"Advertising services"},
                {title:"搭建服务",background: "#197cc3",color: "#fff",titles:"Build service"},
            ],     
            activeNames: ['0'], 
            navtitle:"" ,
            show:"",
            contentss:[
            {
            text:"标准展位（标摊）",
            // text:"标准展位（标摊）",
            imgs:require('../../assets/image/标准展位（标摊）.png'),
            },
            {
            text:"标摊介绍",
            // text:"标摊介绍",
            imgs:require('../../assets/image/标摊介绍.png'),
            },
            {
            text:"特装展位",
            // text:"特装展位",
            imgs:require('../../assets/image/特装展位.png'),
            }
            
        ]
        }
    },
    created() {
         if(this.$route.query.path == undefined || this.$route.query.path == '/'){
             this.navtitle = '餐饮服务'
         }else{
             this.navtitle = this.$route.query.path
              let nav = this.nav
                let navlist =  nav.map((item)=>{
                    if(item.title == this.$route.query.path){
                        item.background = '#197cc3'
                        item.color = '#fff' 
                        if(localStorage.getItem('locale') == 'en-US'){
                            this.navtitle = item.titles
                        }else{
                            this.navtitle = item.title
                        }
                    }else{
                        item.background = '#fff'
                        item.color = '#000' 
                    }
                    return item
                })
                if(localStorage.getItem('locale') == 'en-US'){
                    this.show = false
                }else{
                    this.show = true
                }
             this.nav = navlist
         }
    },
    methods:{
         gopage(val){
            let nav = this.nav
           let navlist =  nav.map((item,index)=>{
                // console.log(item,index);
                if(index == val){
                    item.background = '#197cc3'
                    item.color = '#fff' 
                }else{
                    item.background = '#fff'
                    item.color = '#000' 
                }
                return item
            })
            this.nav = navlist
           if(val == 0){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Food and Beverages'
                }else{
                    this.navtitle = '餐饮服务'
                }
               this.$router.replace({path:'/ExhibitionServices',query:{path:`餐饮服务`}})
               this.activeNames = ["0"]
           }else if(val == 1){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Exhibition equipment rental'
                }else{
                    this.navtitle = '展具租赁'
                }
               this.$router.replace({path:'/ExhibitionServices',query:{path:`展具租赁`}})
               this.activeNames = ["1"]
           }else if(val == 2){
              if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Advertising services'
                }else{
                    this.navtitle = '广告服务'
                }
               this.$router.replace({path:'/ExhibitionServices',query:{path:`广告服务`}})
               this.activeNames = ["2"]
           }else if(val == 3){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Build service'
                }else{
                    this.navtitle = '搭建服务'
                }
               this.$router.replace({path:'/ExhibitionServices',query:{path:`搭建服务`}})
               this.activeNames = ["3"]
           }
        }
    }
}
</script>

<style scoped>
    .ExhibitionServices{
        width: 100%;
        height: 100%;
    }
    .contents{
        width: 100%;
    }
    .navs{
        width: 100%;
        height: auto;
        border-bottom: 0.01rem solid #999;
        margin-bottom: 0.1rem;
    }
    .navs >>> .van-cell{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 0.16rem;
        height: 0.54rem;
    }
    .navs >>> .van-cell__title{
        flex: none;
    }
    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
    .bot{
        margin-top: 0.5rem
    }
</style>