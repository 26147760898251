<!-- 餐饮服务 -->
<template>
  <div class="FoodandBeverages">
      <div class="contents">
             <div class="content">
                <div class="contenttop">
                    <p class="contenttitle">{{ $t(contentss[0].title) }}</p>
                    <p class="contenttext">{{$t(contentss[0].text)}}</p>
                    <img :src="contentss[0].imgs" alt="">
                </div>
                <div class="contentbottom">
                    <div class="left">
                    <p class="bottomtitle">{{ $t(contentss[1].title) }}</p>
                    <p class="bottomtext">{{$t(contentss[1].text)}}</p>
                    <img :src="contentss[1].imgs" alt="">
                    </div>
                    <div class="right">
                    <p class="bottomtitle">{{ $t(contentss[2].title) }}</p>
                    <p class="bottomtext">{{$t(contentss[2].text)}}</p>
                    <img :src="contentss[2].imgs" alt="">
                    </div>
                </div>
            </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            contentss:[
            {
            title:"配套餐饮",
            text:"3000平方米中央厨房、2500平方米的配套厨房可为参展商、会议等人员提供多种形式的美食享受",
            imgs:require('../../assets/image/新中式.png'),
            },
            {
            title:"会展餐饮",
            text:"会展配套餐饮，为展会客人提供便捷、特色餐饮服务",
            imgs:require('../../assets/image/自助午餐.png'),
            },
            {
            title:"宴会餐饮",
            text:"豪华宴会餐饮，为您提供鲜美饕餮美食，尊享极致餐饮服务",
            imgs:require('../../assets/image/龙虾.png'),
            }
            
        ]
        }
    },
}
</script>

<style scoped>
    .exhibition{
        width: 100%;
        height: 100%;
    }
    .contents{
        width: 100%;
    }
    .content{
        width: 100%;
        padding: 0.1rem;
        box-sizing: border-box;
        text-align: center;
        margin-top: 0.2rem;
        background-color: #FAFAFA;
        box-shadow:2px 2px 5px rgba(0,0,0,0.2);
    }
    .contenttitle{
        font-size: 0.24rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 0.5rem;
    }
    .contenttext{
        font-size: 0.15rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 0.22rem;
    }
    .contenttop img{
        width: 95%;
    }
    .contentbottom{
        margin-top: 0.2rem;
        display: flex;
        justify-content: space-evenly;
    }
    .bottomtitle{
        font-size: 0.2rem;
        font-weight: bold;
        color: #333333;
        line-height: 0.4rem;
    }
    .bottomtext{
        font-size: 0.12rem;
        font-family: Microsoft YaHei;
        color: #333333;
        line-height: 0.2rem;
    }
    .contentbottom>div{
        padding: 0.08rem;
    }
    .contentbottom img{
        width: 1.6rem;
        height: 0.8rem;
    }
</style>